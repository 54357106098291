import gql from 'graphql-tag';

export const GET_REGISTRATION_DOCUMENT_LIST = gql`
  query getRequiredRegDocuments($IDRegistration: Int!) {
    getRequiredRegDocuments(IDRegistration: $IDRegistration) {
      totalCount
      data {
        ID
        status
        remarks
        reviewedBy
        label
        forStaffOnly
        documentTypeConfig {
          label
          description
          code
        }
        fkRegistrationChild
        registrationChild {
          firstname
          lastname
          birthCertificate
        }
        fkRegistrationParent
        registrationParent {
          firstname
          lastname
          identificationNo
          registrationParentAttributes {
            data {
              value
            }
          }
        }
        fkRegistrationFamilyMember
        registrationFamilyMember {
          firstname
          lastname
          identificationNo
        }
        documentTags {
          data {
            ID
            document {
              ID
              filename
              updatedAt
              uRL
              preSignedUrl
              user {
                ID
                firstname
                lastname
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_REG_DOCUMENT_UPLOAD_URL = gql`
  mutation getRegistrationDocumentUploadUrl(
    $IDRegistration: Int!
    $file: Upload!
  ) {
    getRegistrationDocumentUploadUrl(
      IDRegistration: $IDRegistration
      file: $file
    ) {
      Url
      Key
      Filename
    }
  }
`;

export const SAVE_REGISTRATION_DOCUMENT = gql`
  mutation saveRegistrationGiroDocument(
    $registrationId: Int!
    $documentUploads: [RegDocumentUpload!]
    $skipApproval: Boolean
  ) {
    saveRegistrationGiroDocument(
      registrationId: $registrationId
      documentUploads: $documentUploads
      skipApproval: $skipApproval
    )
  }
`;
export const SAVE_ECDA_REGISTRATION_DOCUMENT = gql`
  mutation saveECDARegistrationGiroDocument(
    $IDECDARegistration: Int!
    $documentUploads: [RegDocumentUpload!]
    $skipApproval: Boolean
  ) {
    saveECDARegistrationGiroDocument(
      IDECDARegistration: $IDECDARegistration
      documentUploads: $documentUploads
      skipApproval: $skipApproval
    )
  }
`;

export const REMOVE_REG_DOCUMENT = gql`
  mutation removeRegDocument(
    $registrationId: Int!
    $documentId: Int!
    $remarks: String
  ) {
    removeRegDocument(
      registrationId: $registrationId
      documentId: $documentId
      remarks: $remarks
    )
  }
`;

export const APPROVE_REG_DOCUMENT = gql`
  mutation approveRegDocument(
    $registrationId: Int!
    $documentTypeId: Int!
    $remarks: String
  ) {
    approveRegDocument(
      registrationId: $registrationId
      documentTypeId: $documentTypeId
      remarks: $remarks
    )
  }
`;

export const REJECT_REG_DOCUMENT = gql`
  mutation rejectRegDocument(
    $registrationId: Int!
    $documentTypeId: Int!
    $remarks: String
  ) {
    rejectRegDocument(
      registrationId: $registrationId
      documentTypeId: $documentTypeId
      remarks: $remarks
    )
  }
`;

export const REVERT_REG_DOCUMENT = gql`
  mutation revertRegDocument(
    $registrationId: Int!
    $documentTypeId: Int!
    $remarks: String
  ) {
    revertRegDocument(
      registrationId: $registrationId
      documentTypeId: $documentTypeId
      remarks: $remarks
    )
  }
`;

export const SKIP_REG_DOCUMENT = gql`
  mutation skipRegDocument(
    $registrationId: Int!
    $documentTypeId: Int!
    $remarks: String
  ) {
    skipRegDocument(
      registrationId: $registrationId
      documentTypeId: $documentTypeId
      remarks: $remarks
    )
  }
`;

export const SUBMIT_REG_FORM = gql`
  mutation submitRegistrationForm($registrationId: Int!) {
    submitRegistrationForm(registrationId: $registrationId)
  }
`;

export const SEND_REG_DOCUMENT_NOTIFICATION = gql`
  mutation sendDocumentStatusNotification($IDRegistration: Int!) {
    sendDocumentStatusNotification(IDRegistration: $IDRegistration)
  }
`;
