import gql from 'graphql-tag';

// Will add more actions so disable eslint for this file temporary
/* eslint-disable import/prefer-default-export */
export const GET_ENROLLMENT_PLANS_BY_YEAR = gql`
  query getEnrollmentPlansByYear(
    $centreID: Int!
    $year: Int!
    $levelID: Int!
    $programID: Int
  ) {
    getEnrollmentPlansByYear(
      centreID: $centreID
      year: $year
      levelID: $levelID
      programID: $programID
    ) {
      Program {
        ID
        label
        fkLevel
        type
        placementOrder
      }
      TargetEnrollment
      VacanciesOfferedInPrinciple
      ConsumedCapacity
      VacanciesOffered
      RegistrationsInProcess
      RegistrationsCompleted
      Enrollments
      Available
      AvailableActual
      EstPromotionIn
      EstPromotionOut
      PromotionIn
      PromotionOut
      TransferIn
      TransferOut
      Withdrawal
      WaitlistedHS
    }
  }
`;

export const CREATE_ENROLLMENT_PLANNING_REQUEST = gql`
  mutation createEnrollmentPlanningRequest(
    $centreID: Int!
    $levelID: Int!
    $programID: Int!
    $update: CapacityEditDTO!
    $isValidatingExceeding: Boolean!
  ) {
    createEnrollmentPlanningRequest(
      centreID: $centreID
      levelID: $levelID
      programID: $programID
      update: $update
      isValidatingExceeding: $isValidatingExceeding
    ) {
      Success
      ExceedingCapacities {
        IsInfant
        IsExceedingCapacity
        TotalTargetEnrolment
        LicensedCapacity
        Year
        Month
      }
    }
  }
`;

export const LIST_ENROLLMENT_PLANNING_UTILISATION = gql`
  query getEnrolmentPlanningUtilisation($centreID: Int!, $year: Int!) {
    getEnrolmentPlanningUtilisation(centreID: $centreID, year: $year) {
      ICCapacity {
        Numerator
        Denominator
      }
      ICTarget {
        Numerator
        Denominator
      }
      CCCapacity {
        Numerator
        Denominator
      }
      CCTarget {
        Numerator
        Denominator
      }
      TTCapacity {
        Numerator
        Denominator
      }
      TTTarget {
        Numerator
        Denominator
      }
    }
  }
`;

export const LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS = gql`
  query listEnrollmentPlanningChangeRequests($filter: CapacityDraftFilter) {
    listEnrollmentPlanningChangeRequests(capacityDraftFilter: $filter) {
      Draft {
        ID
        fkCentre
        fkLevel
        fkReason
        fkUser
        year
        status
        fkProgramType
        fkReason
        remark
        isRollover
        createdAt
        updatedAt
        centre {
          ID
          label
        }
        level {
          ID
          label
        }
        userByFkUser {
          ID
          firstname
          lastname
        }
        userByFkApprover {
          ID
          firstname
          lastname
        }
      }
      Changes
    }
  }
`;

export const APPROVE_ENROLLMENT_PLAN = gql`
  mutation approveEnrollmentPlanningRequest(
    $draftID: Int!
    $isValidatingExceeding: Boolean!
  ) {
    approveEnrollmentPlanningRequest(
      draftID: $draftID
      isValidatingExceeding: $isValidatingExceeding
    ) {
      Success
      ExceedingCapacities {
        IsInfant
        IsExceedingCapacity
        TotalTargetEnrolment
        LicensedCapacity
        Year
        Month
      }
    }
  }
`;

export const REJECT_ENROLLMENT_PLAN = gql`
  mutation rejectEnrollmentPlanningRequest(
    $draftID: Int!
    $rejectRemark: String!
  ) {
    rejectEnrollmentPlanningRequest(
      draftID: $draftID
      rejectRemark: $rejectRemark
    )
  }
`;

export const LIST_ENROLLMENT_PLANNING_ACTIVITY_LOG = gql`
  query capacityDraftsByFkSchool(
    $schoolID: Int!
    $filter: CapacityDraftFilter
    $pagination: Pagination
    $excludeEmptySnap: Boolean
  ) {
    capacityDraftsByFkSchool(
      IDSchool: $schoolID
      pagination: $pagination
      filter: $filter
      excludeEmptySnap: $excludeEmptySnap
    ) {
      totalCount
      data {
        ID
        fkCentre
        fkLevel
        fkReason
        fkUser
        year
        status
        fkProgramType
        fkReason
        remark
        rejectionRemark
        capacitySnap
        isRollover
        createdAt
        updatedAt
        centre {
          ID
          label
          __typename
        }
        level {
          ID
          label
          __typename
        }
        userByFkUser {
          ID
          firstname
          lastname
          __typename
        }
        userByFkApprover {
          ID
          firstname
          lastname
          __typename
        }
        __typename
      }
    }
  }
`;
