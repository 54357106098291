import gql from 'graphql-tag';
import { LEVEL_FRAGMENT, CONFIG_FRAGMENT } from './commonFragments';

export const FETCH_ALL_LEVELS = gql`
  query FetchAllLevels($filter: LevelFilter, $pagination: Pagination) {
    findAllLevelsConfig(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ...LevelData
      }
    }
  }
  ${LEVEL_FRAGMENT}
`;

export const FETCH_CENTRE_LEVELS = gql`
  query FetchAllCentreLevel(
    $filter: CentreLevelFilter
    $pagination: Pagination
  ) {
    findAllCentreLevel(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        level {
          ID
          label
          code
        }
      }
    }
  }
`;

export const GET_ECDA_COMBINED_WORKING_STATUS = gql`
  query getECDACombinedWorkingStatus($schoolID: Int!) {
    getECDACombinedWorkingStatus(schoolID: $schoolID) {
      data {
        label
        description
      }
    }
  }
`;
export const FETCH_CONFIG_BY_CATEGORIES = gql`
  query fetchAppConfig($filter: CodeFilter) {
    nationalityConfig: findAllConfigByCategory(
      searchCategory: "Nationality"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    raceConfig: findAllConfigByCategory(
      searchCategory: "Race"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    houseHoldIncomeConfig: findAllConfigByCategory(
      searchCategory: "Household_Income_Range"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    qualificationConfig: findAllConfigByCategory(
      searchCategory: "Highest_Qualification"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    maritalStatusConfig: findAllConfigByCategory(
      searchCategory: "Marital_Status"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    ecdaWorkingStatusConfig: findAllConfigByCategory(
      searchCategory: "Ecda_Working_Status"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    workingStatusConfig: findAllConfigByCategory(
      searchCategory: "Working_Status"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    relationshipConfig: findAllConfigByCategory(
      searchCategory: "Relation_Child"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    eventGuestRelationshipConfig: findAllConfigByCategory(
      searchCategory: "event_guest_relation"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    nonParentalRelationshipConfig: findAllConfigByCategory(
      searchCategory: "non_parent_relationship"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    workPlaceStaffConfig: findAllConfigByCategory(
      searchCategory: "workplace_staff"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    ecdaOccupationConfig: findAllConfigByCategory(
      searchCategory: "Ecda_Occupation"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    salaryEmployeeOccupationConfig: findAllConfigByCategory(
      searchCategory: "Occupation"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    selfEmployedOccupationGroupConfig: findAllConfigByCategory(
      searchCategory: "Occupation_Title"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    medicalConditionConfig: findAllConfigByCategory(
      searchCategory: "Medical_Condition"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    nonWorkingReasonConfig: findAllConfigByCategory(
      searchCategory: "non_working_reason"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    supportSchemeConfig: findAllConfigByCategory(
      searchCategory: "child_support_scheme"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    vaccinationsConfig: findAllConfigByCategory(
      searchCategory: "vaccinations"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    nonFoodAllergyConfig: findAllConfigByCategory(
      searchCategory: "non_food_allergy"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    foodAllergyConfig: findAllConfigByCategory(
      searchCategory: "food_allergy"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    childSpecialNeeds: findAllConfigByCategory(
      searchCategory: "child_special_needs"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    preEnrolmentWithdrawalReasons: findAllConfigByCategory(
      searchCategory: "cancel_registration_reason"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    guardianRejectReasonConfig: findAllConfigByCategory(
      searchCategory: "guardian_rejection_reason"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
  }
  ${CONFIG_FRAGMENT}
`;

export const FIND_ALL_CONFIG_CATEGORY = gql`
  query findAllConfigByCategory(
    $searchCategory: String!
    $filter: CodeFilter
    $pagination: Pagination
  ) {
    findAllConfigByCategory(
      searchCategory: $searchCategory
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ...Config
      }
    }
  }
  ${CONFIG_FRAGMENT}
`;

export const FETCH_REGISTRATION_CONFIG = gql`
  query fetchRegistrationConfig($filter: CodeFilter) {
    nonWorkingReasonConfig: findAllConfigByCategory(
      searchCategory: "non_working_reason"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    separationReasonConfig: findAllConfigByCategory(
      searchCategory: "separation_reason"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    selfReferredConfig: findAllConfigByCategory(
      searchCategory: "self_referred"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    typeOfGrantConfig: findAllConfigByCategory(
      searchCategory: "type_of_grant"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    referredByAgencyConfig: findAllConfigByCategory(
      searchCategory: "referred_by_agency"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    nonParentRelationshipConfig: findAllConfigByCategory(
      searchCategory: "non_parent_relationship"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
    medicalConditionConfig: findAllConfigByCategory(
      searchCategory: "Medical_Condition"
      filter: $filter
    ) {
      totalCount
      data {
        ...Config
      }
    }
  }
  ${CONFIG_FRAGMENT}
`;

export const SUGGEST_LEVELS = gql`
  query suggestLevels(
    $IDSchool: Int!
    $IDCentre: Int
    $dateOfBirth: Datetime!
    $enrollmentDate: Datetime!
  ) {
    suggestLevels(
      IDSchool: $IDSchool
      IDCentre: $IDCentre
      dateOfBirth: $dateOfBirth
      enrollmentDate: $enrollmentDate
    ) {
      ID
      code
      label
      fromMonth
      toMonth
    }
  }
`;

export const HOUSEHOLD_INCOME_RANGE_OPTIONS = gql`
  query getHHIIncomeLevelOptions($schoolID: Int!) {
    getHHIIncomeLevelOptions(schoolID: $schoolID) {
      id
      value
      description
    }
  }
`;
