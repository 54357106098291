import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import styled from 'styled-components';
import {
  Grid,
  AppBar,
  Tab,
  Tabs,
  Typography,
  Menu,
  MenuItem,
  ListItemText,
  Paper,
} from '@material-ui/core';
import MoreOptions from '@material-ui/icons/MoreVert';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { getPrimaryColor, getQueryParams } from '../../utils';
import StringUtil from '../../utils/stringUtil';
import setData from '../../redux/actions/SetData';

const { toSentenceCase } = StringUtil;
const TabContainer = ({ children, dir, swipeableViewPadding }) => (
  <Typography
    component="div"
    dir={dir}
    style={{ paddingTop: swipeableViewPadding ? 24 : 0 }}
  >
    {children}
  </Typography>
);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledTab = styled(Tab)`
  font-size: ${props => props.tabLabelFontSize || '16px'};
  font-family: ${props => props.tabLabelFontFamily || 'Lato-Bold'};
  text-transform: none;
  &.MuiTab-textColorPrimary {
    color: ${props => props.theme.baseColor.neutral.black};
  }
  &.MuiTab-textColorPrimary.Mui-selected {
    color: ${props => props.primaryColor || getPrimaryColor(props)};
  }
`;

class TabGenerator extends Component {
  static urlParams = ['tabValue'];

  constructor(arg) {
    super(arg);
    const queryParams = getQueryParams();
    const tabValue = parseInt(get(queryParams, 'tabValue'), 10) || 0;
    const selectedOption =
      parseInt(get(queryParams, 'selectedOption'), 10) || null;
    this.state = {
      tabValue,
      anchorEl: null,
      selectedOption,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.moreOptionHandleChange = this.moreOptionHandleChange.bind(this);
  }

  componentDidMount() {
    const { setData } = this.props;
    setData('tabValue', 0);
  }

  moreOptionHandleChange(option) {
    this.setState(
      {
        anchorEl: null,
        selectedOption: option,
      },
      () => {
        const { tabChanged } = this.props;
        if (tabChanged) {
          tabChanged(option, 1);
        }
      }
    );
  }

  handleChange(event, tabValue) {
    const { setData } = this.props;
    setData('tabValue', tabValue);
    this.setState(
      {
        tabValue,
        selectedOption: null,
      },
      () => {
        const { tabChanged } = this.props;
        if (tabChanged) {
          tabChanged(tabValue);
        }
      }
    );
  }

  handleChangeIndex(tabValue) {
    const { setData } = this.props;
    setData('tabValue', tabValue);
  }

  render() {
    let { tabValue } = this.state;

    const { anchorEl } = this.state;

    let { selectedOption } = this.state;
    const {
      theme,
      tabData,
      className,
      tabClass,
      viewClass,
      tabSize,
      activeTab,
      notify,
      appBarColor,
      showMoreOption,
      moreOption,
      tabLabelStyleClass,
      tabDirection,
      tabJustify,
      containerStyle = '',
      slideStyle = {},
      style = {},
      tabLabelProps = {},
      swipeableViewPadding = true,
      disableScrolling = false,
      variant = 'fullWidth',
      tabLabelFontSize = '14px',
      isFreeTabLabel = false,
      disabled = false,
      primaryColor,
    } = this.props;

    let tabLabels = [];

    if (tabData) {
      tabLabels = Object.keys(tabData);
    }

    tabValue = activeTab || 0;
    if (typeof tabValue === 'string') {
      const splitStr = tabValue.toLowerCase().split('-');
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      selectedOption = splitStr.join(' ');
    }

    const tabs = (
      <Tabs
        value={tabValue}
        onChange={this.handleChange}
        textColor="primary"
        className={`${tabClass} s7t-space-evently`}
        variant={variant}
        TabIndicatorProps={{
          style: {
            backgroundColor: primaryColor || getPrimaryColor(this.props),
          },
        }}
      >
        {tabLabels.map((eachLabel, idx) => {
          const keyName = `${idx}-label-tabgen`;
          const props = get(tabData[eachLabel], 'props', {});
          if (notify.indexOf(eachLabel) > -1) {
            return (
              <StyledTab
                icon={
                  <span
                    className="s7t-notify-change-bubble s7t-tab-style"
                    style={{
                      background: primaryColor || getPrimaryColor(this.props),
                    }}
                  />
                }
                key={keyName}
                label={toSentenceCase(eachLabel)}
                className={`${tabLabelStyleClass}`}
              />
            );
          }

          const tabLabel = `${eachLabel} ${get(
            tabData[eachLabel],
            'count',
            ''
          )}`;

          let adjustedTabLabel = toSentenceCase(tabLabel);

          if (isFreeTabLabel || tabLabel.trim() === 'CSS') {
            adjustedTabLabel = tabLabel;
          }

          return (
            <StyledTab
              style={{
                fontSize: tabLabelFontSize,
                color: disabled ? `${theme.baseColor.neutral.mediumGrey}` : '',
              }}
              className={`s7t-tab-style ${tabLabelStyleClass}`}
              {...props}
              {...tabLabelProps}
              key={keyName}
              label={adjustedTabLabel}
              disabled={disabled}
              primaryColor={primaryColor}
            />
          );
        })}
      </Tabs>
    );
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            className={containerStyle}
            direction={tabDirection}
            justify={tabJustify}
          >
            <Grid
              item
              xs={
                showMoreOption
                  ? parseInt(tabSize, 10) - 1
                  : parseInt(tabSize, 10)
              }
            >
              <AppBar
                className={className}
                position="static"
                color={appBarColor}
              >
                {tabs}
              </AppBar>
            </Grid>
            {showMoreOption && (
              <Grid item xs={1} className="w3-center w3-margin-top s7t-cursor">
                <MoreOptions
                  size="large"
                  color="primary"
                  onClick={e => this.setState({ anchorEl: e.target })}
                />
                <StyledMenu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() =>
                    this.setState({
                      anchorEl: null,
                      selectedOption: null,
                    })
                  }
                >
                  {Object.keys(moreOption).map(option => (
                    <MenuItem
                      key={option}
                      onClick={() => this.moreOptionHandleChange(option)}
                    >
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </StyledMenu>
              </Grid>
            )}
          </Grid>
          {showMoreOption && selectedOption ? (
            <Paper className="s7t-margin-top s7t-classmgmt-table-style s7t-container-background">
              {moreOption[selectedOption]}
            </Paper>
          ) : (
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={tabValue}
              className={`${viewClass} swipeableViews`}
              onChangeIndex={this.handleChangeIndex}
              slideStyle={slideStyle}
              style={style}
              disabled={disableScrolling}
            >
              {tabLabels.map((eachLabel, idx) => {
                const keyName = `${idx}-data-tabgen`;

                return (
                  <TabContainer
                    key={keyName}
                    dir={theme.direction}
                    swipeableViewPadding={swipeableViewPadding}
                  >
                    {tabValue === idx && tabData[eachLabel].data}
                  </TabContainer>
                );
              })}
            </SwipeableViews>
          )}
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
});

TabGenerator.defaultProps = {
  className: '',
  tabClass: '',
  viewClass: '',
  tabSize: '12',
  appBarColor: 'default',
  notify: [],
};

TabGenerator.propTypes = {
  className: PropTypes.string,
  tabClass: PropTypes.string,
  viewClass: PropTypes.string,
  tabData: PropTypes.objectOf(PropTypes.object).isRequired,
  tabSize: PropTypes.string,
  appBarColor: PropTypes.string,
  notify: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = () => ({});
const mapDispatchToProps = { setData };

export default withTheme(
  withStyles(styles, {
    withTheme: true,
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TabGenerator)))
);
