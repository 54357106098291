/* eslint-disable import/no-cycle */
import { get, mapValues, groupBy, uniq, compact } from 'lodash';
import DatadogHandler from 'utils/datadog';
import {
  MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT,
  classOpsService,
} from '../../../../../utils/msEndpointConstants';
import { DEFAULT_ERROR } from '../../../../../utils/constants';
import { msGetListDomain } from '../../../PortfoliosManagement/Portfolios/PortfoliosHelpers';

const {
  SEARCH_CLASS_ACTIVITY_TAG,
  DELETE_CLASS_ACTIVITYIES,
  CREATE_OR_UPDATE_CLASS_ACTIVITY,
  PUBLISH_CLASS_ACTIVITY,
  UPDATE_CLASS_ACTIVITY_STATUS,
  GET_LESSON_PLANS,
  GET_LESSON_PLAN_BY_ID,
  CREATE_CLASS_ACTIVITY_TAG,
} = MS_PHASE_2B_CLASS_ACTIVITY_ENPOINT.CLASS_ACTIVITY;

export const transformLessonPlanByLevel = (res = {}) => {
  const data = get(res, 'data.data') || [];
  return data.map(dt => ({
    ID: get(dt, 'id'),
    label: get(dt, 'label'),
    title: get(dt, 'title'),
    termCode: get(dt, 'term_code_string'),
    fkLevel: get(dt, 'fk_level'),
  }));
};
export const transformLessonPlanLearningGoals = (res = {}) => {
  const data = get(res, 'data.data') || [];
  return mapValues(groupBy(data, 'fk_lesson_plan'), lessonPlanLearningGoal =>
    lessonPlanLearningGoal.map(lPLG => ({
      ID: get(lPLG, 'id'),
      fkLearningGoal: get(lPLG, 'fk_learning_goal.id'),
      fkLessonPlan: get(lPLG, 'fk_lesson_plan'),
      learningGoal: {
        ID: get(lPLG, 'fk_learning_goal.id'),
        title: get(lPLG, 'fk_learning_goal.title'),
        fkLearningGoal: get(lPLG, 'fk_learning_goal.fk_learning_goal'),
        code: get(lPLG, 'fk_learning_goal.code'),
      },
    }))
  );
};
export const transformGetLessonPlans = (
  lessonPlans = [],
  learningGoals = {}
) => {
  const lessonPlanUpdated = lessonPlans.map(lP => ({
    ...lP,
    ...(learningGoals[get(lP, 'ID')] && {
      lessonPlanLearningGoals: {
        data: learningGoals[get(lP, 'ID')],
      },
    }),
  }));
  return {
    data: {
      getLessonPlan: {
        data: lessonPlanUpdated,
      },
    },
  };
};

export const msSearchClassActivityTag = async (_, reqBody) => {
  try {
    const body = {
      tag_name: get(reqBody, 'tagName'),
    };
    if (!get(reqBody, 'tagName')) {
      return {
        searchClassActivityTag: {
          data: [],
        },
      };
    }
    const res = await classOpsService().post(SEARCH_CLASS_ACTIVITY_TAG, body);
    if (get(res, 'status') === 200) {
      return {
        searchClassActivityTag: {
          data: get(res, 'data.data', []).map(item => {
            return {
              ID: get(item, 'id'),
              name: get(item, 'name'),
            };
          }),
        },
      };
    }
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return {
      error: get(e, 'response.data.error') || get(e, 'message', DEFAULT_ERROR),
    };
  }
};

export const msDeleteClassActivity = async (_, reqBody) => {
  try {
    const body = {
      data: {
        class_activity_ids: get(reqBody, 'IDClassActivities'),
      },
    };
    const res = await classOpsService().delete(DELETE_CLASS_ACTIVITYIES, body);
    if (get(res, 'status') === 200) {
      return {
        data: {
          deleteClassActivities: true,
        },
      };
    }
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msCreateOrUpdateClassActivity = async (_, reqBody) => {
  try {
    const body = {
      allow_comment: get(reqBody, 'dto.AllowComment'),
      description: get(reqBody, 'dto.Description'),
      display_date: get(reqBody, 'dto.DisplayDate'),
      final_status: get(reqBody, 'dto.FinalStatus'),
      fk_domain: get(reqBody, 'dto.FKDomain'),
      fk_lesson_plan: get(reqBody, 'dto.FKLessonPlan'),
      fk_sub_domain: get(reqBody, 'dto.FKSubDomain'),
      id_children: get(reqBody, 'dto.IDChildren'),
      id_class: get(reqBody, 'dto.IDClass')
        ? parseInt(get(reqBody, 'dto.IDClass'))
        : '',
      images: get(reqBody, 'dto.Images', []).map(item => {
        return {
          caption: get(item, 'Caption'),
          format: get(item, 'Format'),
          index: get(item, 'Index'),
          status: get(item, 'Status'),
          status_process: get(item, 'StatusProcess'),
          type: get(item, 'Type'),
          url: get(item, 'urlPath'),
        };
      }),
      interpretation: get(reqBody, 'dto.Interpretation', ''),
      involvement: get(reqBody, 'dto.Involvement'),
      learning_goals: get(reqBody, 'dto.LearningGoals'),
      link: get(reqBody, 'dto.Link'),
      notify_parent: get(reqBody, 'dto.NotifyParent'),
      pinned: get(reqBody, 'dto.Pinned'),
      stickers: get(reqBody, 'dto.Stickers'),
      tags: get(reqBody, 'dto.Tags'),
      title: get(reqBody, 'dto.Title'),
      type: get(reqBody, 'dto.Type'),
      ...(get(reqBody, 'classActivityID')
        ? { id: get(reqBody, 'classActivityID') }
        : {}),
      wellbeing: get(reqBody, 'dto.Wellbeing'),
    };
    const res = await classOpsService().post(
      CREATE_OR_UPDATE_CLASS_ACTIVITY,
      body
    );
    return {
      data: {
        createOrUpdateClassActivity: {
          ID: get(res, 'data.id'),
        },
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msUpdateClassActivityStatus = async (_, reqBody) => {
  try {
    const body = {
      id: get(reqBody, 'IDClassActivity'),
      status: get(reqBody, 'status'),
    };
    const res = await classOpsService().put(UPDATE_CLASS_ACTIVITY_STATUS, body);
    return {
      data: {
        updateClassActivityStatus: {
          ID: get(res, 'data.id'),
          status: get(res, 'data.status_string'),
          type: get(res, 'data.type_string'),
        },
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msPublishClassActivity = async (_, reqBody) => {
  try {
    const res = await classOpsService().post(
      PUBLISH_CLASS_ACTIVITY(reqBody?.classActivityID),
      {}
    );
    return {
      data: {
        publishClassActivity: {
          ID: get(res, 'data.id'),
          status: get(res, 'data.status_string'),
          type: get(res, 'data.type_string'),
        },
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const transformDomainData = (data = {}) => {
  return {
    totalCount: get(data, 'totalCount'),
    data: (get(data, 'data') || []).map(dt => ({
      ID: get(dt, 'id'),
      title: get(dt, 'title'),
    })),
  };
};

export const transformLearningGoalByClass = (groupByKey, resp = []) => {
  const data = get(resp, 'data') || [];
  const totalCount = get(resp, 'totalCount', 0);

  return {
    totalCount,
    data: mapValues(groupBy(data, groupByKey), domlist =>
      domlist.map(dom => ({
        ID: get(dom, 'id'),
        title: get(dom, 'title'),
        description: get(dom, 'description'),
        fkLearningGoal: get(dom, 'fk_learning_goal'),
        code: get(dom, 'code'),
      }))
    ),
  };
};

export const transformMSToMono = (
  rootLevel = {},
  subQuery1 = {},
  subQuery2 = {}
) => {
  const data = get(rootLevel, 'data') || [];
  const subQuery1Data = get(subQuery1, 'data') || [];
  const subQuery2Data = get(subQuery2, 'data') || [];
  const result = data.map(dt => {
    const lGByClass = subQuery1Data[get(dt, 'ID')];
    if (lGByClass) {
      const lGByClassUpdated = lGByClass.map(lg => {
        if (subQuery2Data[get(lg, 'ID')]) {
          lg.learningGoalsByClass = {
            totalCount: subQuery2Data[get(lg, 'ID')].length,
            data: subQuery2Data[get(lg, 'ID')],
          };
        }
        return lg;
      });
      dt.learningGoalsByClass = {
        totalCount: lGByClassUpdated.length,
        data: lGByClassUpdated,
      };
    }
    return dt;
  });
  return { ...rootLevel, data: result };
};

export const msFetchDomainListData = async (_, reqBody) => {
  try {
    const result = await msGetListDomain(reqBody);
    const data = transformDomainData(result[0]);
    const resp = transformLearningGoalByClass('fk_domain', {
      totalCount: get(result, '[1].length', 0),
      data: result[1],
    });
    const learningGoals = transformLearningGoalByClass('fk_learning_goal', {
      totalCount: get(result, '[3].totalCount', 0),
      data: get(result, '[3].data', []),
    });
    const domainList = transformMSToMono(data, resp, learningGoals);
    return {
      getListDomain: domainList,
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
  }
};

export const msGetLessonPlans = async (_, reqBody) => {
  try {
    const body = {
      level_id: get(reqBody, 'levelID'),
      term_code: get(reqBody, 'filter.termCode'),
      version: get(reqBody, 'filter.version'),
    };
    const res = await classOpsService().post(GET_LESSON_PLANS, body);
    const data = transformLessonPlanByLevel(res);
    const lessonPlanIds = compact(
      uniq(get(res, 'data.data') || []).map(lP => get(lP, 'id'))
    );
    let lessonPlans = {};
    if (lessonPlanIds.length > 0) {
      const lessonPlanLearningGoals = await classOpsService().post(
        GET_LESSON_PLAN_BY_ID,
        {
          lesson_plan_ids: lessonPlanIds,
        }
      );
      const lessonPlanLearningGoalData = transformLessonPlanLearningGoals(
        lessonPlanLearningGoals
      );
      lessonPlans = transformGetLessonPlans(data, lessonPlanLearningGoalData);
    }
    return lessonPlans;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
  }
};

export const transformCreateTagData = (res = {}) => {
  const data = get(res, 'data');
  return {
    data: {
      ...(get(data, 'id') && {
        createClassActivityTag: {
          ID: get(data, 'id'),
          name: get(data, 'name'),
          nameLowercase: get(data, 'name_lowercase'),
          active: get(data, 'active'),
          fkSchool: get(data, 'fk_school'),
        },
      }),
    },
  };
};

export const msCreateClassActivitytag = async (_, reqBody) => {
  try {
    const body = {
      fk_school: parseInt(get(reqBody, 'schoolID'), 10),
      name_lowercase: (get(reqBody, 'tagName') || '').toLowerCase(),
      name: get(reqBody, 'tagName'),
    };
    const result = await classOpsService().post(
      CREATE_CLASS_ACTIVITY_TAG,
      body
    );
    const resp = transformCreateTagData(result);
    return resp;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
  }
};

export default {
  msSearchClassActivityTag,
  msDeleteClassActivity,
  msUpdateClassActivityStatus,
  msPublishClassActivity,
  msFetchDomainListData,
  msGetLessonPlans,
  msCreateClassActivitytag,
};
